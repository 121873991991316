.bg-maroon {
  background-color: #192c51;
}

.bg-dark-maroon {
  background-color: rgb(25, 44, 81);
}

.bg-light-blue {
  background-color: #cddce7;
}

.bg-gradient-maroon-1 {
  background: rgb(25, 44, 81);
  background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
}

.bg-gradient-light-blue-1 {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(223, 236, 247) 100%);
}

.bg-gradient-light-blue-1-inverse {
  background: rgb(25, 44, 81);
  background: linear-gradient(90deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
}

.bg-gradient-light-blue-2 {
  background: rgb(202, 224, 244);
  background: linear-gradient(0deg, rgba(202, 224, 244, 0.6) 0%, rgba(255, 255, 255, 0) 52%, rgba(255, 255, 255, 0) 100%);
}

body {
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
  color: #272626;
}

.supertitle {
  margin-bottom: 0.5rem;
}

strong,
b {
  font-size: 800;
}

.slider-title,
.slider-subtitle,
.banner-title {
  font-weight: 400;
}
.slider-title,
.slider-title u,
.slider-subtitle,
.slider-subtitle u,
.banner-title,
.banner-title u {
  text-underline-offset: 6px;
}

.slider-title {
  font-weight: bolder;
  font-size: 1.6rem;
}

.slider-subtitle {
  font-size: 1.34rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 700;
}

.dynamic-content *:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 1.7rem;
  margin-bottom: 1.85rem;
}

h2,
.h2 {
  font-size: 1.5rem;
  margin-bottom: 1.64rem;
}

h3,
.h3 {
  font-size: 1.34rem;
  margin-bottom: 1.47rem;
}

h4,
.h4 {
  font-size: 1.19rem;
  margin-bottom: 1.3rem;
}

h5,
.h5 {
  font-size: 1.08rem;
  margin-bottom: 1.16rem;
}

h6,
.h6 {
  font-size: 0.97rem;
  margin-bottom: 1.02rem;
}

.text-lg {
  font-size: 0.9rem;
}

.text-md {
  font-size: 0.85rem;
}

.text-sm {
  font-size: 0.78rem;
}

.text-xs {
  font-size: 0.7rem;
}

@media (min-width: 767px) {
  .slider-title {
    font-size: 2.2rem;
  }
  .slider-subtitle {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .slider-title {
    font-size: 2.35rem;
  }
  .slider-subtitle {
    font-size: 1.65rem;
  }
}
@media (min-width: 1200px) {
  body {
    font-size: 0.9rem;
  }
  .slider-title {
    font-size: 2.7rem;
  }
  .slider-subtitle {
    font-size: 1.8rem;
  }
  h1,
  .h1 {
    font-size: 2.3rem;
    margin-bottom: 2.54rem;
  }
  h2,
  .h2 {
    font-size: 1.85rem;
    margin-bottom: 2.05rem;
  }
  h3,
  .h3 {
    font-size: 1.54rem;
    margin-bottom: 1.7rem;
  }
  h4,
  .h4 {
    font-size: 1.28rem;
    margin-bottom: 1.42rem;
  }
  h5,
  .h5 {
    font-size: 1.15rem;
    margin-bottom: 1.23rem;
  }
  h6,
  .h6 {
    font-size: 1.1rem;
    margin-bottom: 1.03rem;
  }
}
@media (min-width: 1430px) {
  body {
    font-size: 0.9rem;
  }
  .slider-title {
    font-size: 3.2rem;
  }
  .slider-subtitle {
    font-size: 2.2rem;
  }
  h1,
  .h1 {
    font-size: 2.5rem;
    margin-bottom: 2.8rem;
  }
  h2,
  .h2 {
    font-size: 2.05rem;
    margin-bottom: 2.3rem;
  }
  h3,
  .h3 {
    font-size: 1.72rem;
    margin-bottom: 1.88rem;
  }
  h4,
  .h4 {
    font-size: 1.43rem;
    margin-bottom: 1.54rem;
  }
  h5,
  .h5 {
    font-size: 1.2rem;
    margin-bottom: 1.26rem;
  }
  h6,
  .h6 {
    font-size: 1.1rem;
    margin-bottom: 1.03rem;
  }
}
.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 769px) {
  .section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1430px) {
  .section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: #fff;
}
header.header-transparent {
  background-color: transparent;
}
header.header-transparent:not(.state-scroll) .header-social-media .link {
  background-color: transparent !important;
}
header .top-nav ul {
  margin-bottom: 0;
}
header .top-nav ul .nav-item:not(:last-child) {
  margin-right: 1.5rem;
}
header .top-nav .nav-item {
  display: inline-block;
}
header .top-nav .nav-item .nav-link {
  font-size: 0.85rem;
  padding-left: 0;
  padding-right: 0;
}
header .top-nav .nav-item .nav-link i {
  font-size: 1.1rem;
}
header .top-nav .nav-item .link {
  color: #272626;
}
header .top-nav .nav-icon-wrapper {
  display: inline-flex;
  align-items: center;
}
header .top-nav .nav-icon-wrapper .icon {
  filter: invert(1);
  width: 35px;
}
header .top-nav .nav-icon-wrapper .title {
  font-size: inherit;
  font-weight: 600;
  line-height: 1.2;
}
header .navbar > .container {
  padding-right: 0;
  padding-left: 0;
}
header .navbar .nav-item-logo {
  display: none;
}
header .navbar .logo-link .logo-link-img {
  width: 80px;
}
header .navbar .nav-item {
  z-index: 1;
}
header .navbar .nav-item .nav-link {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  color: #272626;
}
header .navbar .nav-item .nav-link.btn {
  color: #fff;
}
header .navbar .nav-item .dropdown-toggle {
  color: #272626;
  font-size: 0.9rem;
  margin-top: -1px;
  cursor: pointer;
}
header .navbar .nav-item .dropdown-toggle:not(.language-dropdown) {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
header .navbar .nav-item .dropdown-toggle::after {
  content: none;
}
header .navbar .nav-item .dropdown-toggle.language-dropdown + .dropdown-menu {
  min-width: unset;
}
header .navbar .nav-item:not(:last-child) {
  margin-right: 1rem;
}
header .navbar .nav-item.dropdown .nav-link {
  padding-right: calc(0.5rem + 15px);
}
header .navbar .nav-item.dropdown .dropdown-menu {
  border-radius: 0;
  border: none;
  padding: 0.2rem;
  left: 0;
}
header .navbar .nav-item.dropdown .dropdown-menu li:not(:last-child) {
  margin-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
header .navbar .nav-item.dropdown .dropdown-menu a,
header .navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
  color: #272626;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.6rem 0.7rem;
  position: relative;
  transition: 0.15s;
}
header .navbar .nav-item.dropdown .dropdown-menu a::before,
header .navbar .nav-item.dropdown .dropdown-menu .dropdown-item::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0.1s;
  background: rgb(25, 44, 81);
  background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
  opacity: 0;
  z-index: -1;
}
header .navbar .nav-item.dropdown .dropdown-menu a:hover,
header .navbar .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  color: #fff !important;
  background-color: unset;
}
header .navbar .nav-item.dropdown .dropdown-menu a:hover::before,
header .navbar .nav-item.dropdown .dropdown-menu .dropdown-item:hover::before {
  opacity: 1;
}
header .navbar .nav-item.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
}
header .navbar-brand .logo {
  width: 100px;
}
header .header-social-media {
  list-style: none;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}
header .header-social-media .nav-item:not(:last-child) {
  margin-right: 1rem;
}
header .header-social-media .link {
  font-size: 1.2rem;
  display: inline-flex;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  background-color: #374f7f !important;
  color: #fff !important;
}
header .header-social-media .nav-link {
  padding: 0.1rem 0.3rem;
}
header + .page-content {
  padding-top: 60px;
}

@media (max-width: 1200px) {
  header .top-nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    width: 100%;
    background-color: #fff;
  }
  header .navbar {
    position: fixed;
    top: 0;
    height: calc(100vh + 10px);
    overflow-y: auto;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  header .navbar.show {
    visibility: visible;
    opacity: 1;
  }
  header .navbar .navbar-nav {
    display: inline-block;
  }
  header .navbar .nav-item .nav-link,
  header .navbar .nav-item .dropdown-toggle {
    color: #272626;
  }
  header .navbar .nav-item .nav-link {
    font-size: 1rem;
  }
  header .menu-wrapper {
    display: inline-flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    min-width: 400px;
  }
  header .header-social-media {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e3e3e3;
    justify-content: center;
  }
  header .header-social-media .link {
    font-size: 1.5rem;
    border-radius: 50%;
    border: 1px solid #272626;
    height: 35px;
    width: 35px;
  }
  #navbarMenu::after {
    content: "";
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 25%;
    max-width: 220px;
    min-width: 160px;
    height: 25%;
    background-image: url("/themes/dentist-theme/assets/img/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    opacity: 0.2;
  }
}
@media (max-width: 991px) {
  header .top-nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    width: 100%;
    background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
  }
  header .top-nav .nav-item .link {
    color: #fff;
  }
  header .top-nav .nav-icon-wrapper .icon {
    filter: unset;
  }
}
@media (max-width: 430px) {
  header .menu-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    min-width: unset;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .dropdown-toggle {
    z-index: -1;
  }
  header .navbar .nav-item.dropdown .dropdown-menu {
    z-index: -1;
  }
  header .navbar-brand .logo {
    width: 100px;
  }
  header .top-nav .nav-icon-wrapper .icon {
    width: 40px;
  }
  header.header-transparent .border-b {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  header .border-b {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  header + .page-content {
    padding-top: 77px;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-nav {
    align-items: center;
  }
  .navbar .nav-item {
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1;
  }
  .navbar .nav-item .dropdown-menu {
    top: 100%;
  }
  .navbar .nav-item .nav-link {
    text-transform: uppercase;
    font-weight: bold;
    color: #272626;
  }
  .navbar .nav-item .nav-link.btn {
    color: #fff;
  }
  .navbar .nav-item .dropdown-toggle {
    color: #272626;
    font-size: 0.9rem;
    margin-top: -1px;
    cursor: pointer;
  }
  .navbar .nav-item .dropdown-toggle:not(.language-dropdown) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .navbar .nav-item .dropdown-toggle::after {
    content: none;
  }
  .navbar .nav-item .dropdown-toggle.language-dropdown + .dropdown-menu {
    min-width: unset;
  }
  .navbar .nav-item:not(:last-child) {
    margin-right: 1rem;
  }
  .navbar .nav-item.dropdown .nav-link {
    padding-right: calc(0.5rem + 15px);
  }
  .navbar .nav-item.dropdown .dropdown-menu {
    border-radius: 0;
    border: none;
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    transform: translateY(-30%);
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
  .navbar .nav-item.dropdown .dropdown-menu li:not(:last-child) {
    margin-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar .nav-item.dropdown .dropdown-menu a,
  .navbar .nav-item.dropdown .dropdown-menu .dropdown-item {
    color: #272626;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0.6rem 0.7rem;
    position: relative;
    transition: 0.15s;
  }
  .navbar .nav-item.dropdown .dropdown-menu a::before,
  .navbar .nav-item.dropdown .dropdown-menu .dropdown-item::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.1s;
    background: rgb(25, 44, 81);
    background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
    opacity: 0;
    z-index: -1;
  }
  .navbar .nav-item.dropdown .dropdown-menu a:hover,
  .navbar .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
    color: #fff !important;
    background-color: unset;
  }
  .navbar .nav-item.dropdown .dropdown-menu a:hover::before,
  .navbar .nav-item.dropdown .dropdown-menu .dropdown-item:hover::before {
    opacity: 1;
  }
  .navbar .nav-item.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
  }
  header:not(.header-transparent) + .page-content {
    padding-top: 148px;
  }
  header.header-transparent + .page-content {
    padding-top: unset;
  }
  header.header-transparent .nav-item .nav-link,
  header.header-transparent .nav-item .link {
    color: #fff;
  }
  header.header-transparent .nav-item .dropdown-toggle {
    color: #fff;
  }
  header.header-transparent .nav-icon-wrapper .icon {
    filter: unset;
  }
  header .navbar .nav-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  header .navbar .nav-item .nav-link {
    font-size: 0.77rem;
  }
  header .navbar-brand .logo {
    width: 120px;
  }
  header .top-nav .nav-icon-wrapper .icon {
    width: 45px;
  }
  header .top-nav .nav-icon-wrapper .title {
    font-size: 0.97rem;
  }
  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  header:not(.header-transparent) + .page-content {
    padding-top: 148px;
  }
  header .navbar .nav-item .nav-link {
    font-size: 0.85rem;
  }
}
footer {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
footer .social-media-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 0.7rem 1rem;
  background-color: #cddce7;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10rem;
}
footer .social-media-wrapper li:not(:last-child) {
  padding-right: 2rem;
  position: relative;
}
footer .social-media-wrapper li:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background-color: rgba(51, 51, 51, 0.1019607843);
}
footer .social-media-wrapper .link {
  color: #272626;
  font-size: 1.7rem;
}
footer .logo {
  width: 150px;
}
footer .logo-text {
  font-family: "Times New Roman";
  font-style: italic;
  text-transform: uppercase;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 2px 20px 6px;
  display: inline-block;
  font-size: 1.1rem;
}
footer .section {
  padding-top: 50px;
  padding-bottom: 50px;
}
footer .footer-contact .link {
  font-size: 1.1rem;
  font-weight: bold;
}
footer .footer-contact .icon {
  font-size: 1.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}
footer .footer-social-media {
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
footer .footer-social-media li:not(:last-child) {
  margin-right: 1.2rem;
}
footer .footer-social-media .link {
  color: #272626;
  font-size: 1.4rem;
  display: inline-flex;
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
}
footer .footer-bottom {
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
footer .footer-bottom .link {
  text-decoration: underline;
}
footer .footer-nav li:not(:last-child) {
  margin-right: 1rem;
}
footer .back-to-top-btn {
  text-decoration: none !important;
  color: #fff;
  display: inline-flex;
  align-items: end;
}
footer .back-to-top-btn i {
  margin-left: 0.5rem;
  position: relative;
  top: 1px;
  padding: 7px;
  font-size: 1.5rem;
  background-color: #fff;
  color: #272626;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.banner.banner-xl,
.banner.banner-xl .bg-video {
  min-height: 100vh;
}
.banner.banner-lg,
.banner.banner-lg .bg-video {
  min-height: 90vh;
}
.banner.banner-md,
.banner.banner-md .bg-video {
  min-height: 400px;
}
.banner.banner-sm,
.banner.banner-sm .bg-video {
  min-height: 300px;
}
.banner.bg-img {
  background-size: cover;
  background-position: center;
}
.banner img.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.banner .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.banner .content {
  position: relative;
  z-index: 2;
  min-height: inherit;
}

@media (max-width: 991px) {
  .banner.banner-lg, .banner.banner-xl {
    min-height: 500px;
    max-height: 70dvh;
  }
  .banner.banner-lg .bg-video, .banner.banner-xl .bg-video {
    min-height: inherit;
    max-height: inherit;
  }
}
.bg-icon {
  position: relative;
}

.bg-icon-tooth::after {
  content: "";
  background-image: url("/public/img/icons/teeth.png");
  position: absolute;
  bottom: -20px;
  right: -20px;
  display: block;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
  opacity: 0.1;
}

.btn-primary,
.btn-secondary,
.btn-outline-primary,
.btn-outline-secondary {
  border-radius: 10rem;
  font-size: 0.9rem;
  transition: 0.3s ease-in-out;
}
.btn-primary i,
.btn-secondary i,
.btn-outline-primary i,
.btn-outline-secondary i {
  margin-left: 0.3rem;
  font-size: 1.2rem;
  position: relative;
  top: 1px;
}
.btn-primary:focus,
.btn-secondary:focus,
.btn-outline-primary:focus,
.btn-outline-secondary:focus {
  box-shadow: unset !important;
}

.btn-primary {
  background: rgb(25, 44, 81);
  background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
  border-color: rgba(255, 255, 255, 0);
  color: #fff;
}
.btn-primary:hover {
  background: #fff;
  border-color: #374f7f;
  color: #374f7f;
}

.btn-secondary {
  background-color: #c6cfd6;
  border-color: #c6cfd6;
  color: #272626;
}

.btn-white {
  background-color: #fff;
  border-color: #fff;
  color: #272626;
}

.btn-primary,
.btn-secondary,
.btn-white {
  padding: 1rem 1.5rem;
  font-weight: 500;
}

.link {
  transition: 0.3s;
  text-decoration: none;
}
.link.link-dark {
  color: #272626;
}
.link.link-maroon {
  color: #192c51;
}
.link.link-white {
  color: #fff;
}
.link.link-unstyled {
  color: inherit;
}

@media (min-width: 1500) {
  .container {
    max-width: 1480px;
  }
}
.slider .slide-wrapper {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.slider .slick-list {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

.theme-slider.slider-full {
  min-height: 100dvh;
}
.theme-slider.slider-lg {
  min-height: 500px;
  height: calc(100dvh - 175px);
}
.theme-slider .slide {
  min-height: inherit;
  position: relative;
}
.theme-slider .slide .slide-img,
.theme-slider .slide .slide-video {
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.theme-slider .slide .content {
  position: relative;
  z-index: 1;
  padding: 2rem 0;
}
.theme-slider .slick-list,
.theme-slider .slick-track,
.theme-slider .slide,
.theme-slider .content {
  min-height: inherit;
  height: inherit;
}

.dots-style-1 .slick-dots {
  list-style: none;
  padding-left: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}
.dots-style-1 .slick-dots li {
  display: inline-block;
}
.dots-style-1 .slick-dots li:not(:last-child) {
  margin-right: 0.5rem;
}
.dots-style-1 .slick-dots li button {
  font-size: 0;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  border: 1px solid #fff;
  padding: 0;
}
.dots-style-1 .slick-dots li.slick-active button {
  background-color: #fff;
}

.slider-nav .slide-prev,
.slider-nav .slide-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 3rem;
  opacity: 0.3;
  transition: 0.3s;
}
.slider-nav .slide-prev:hover,
.slider-nav .slide-next:hover {
  opacity: 1;
}
.slider-nav .slide-prev {
  left: 23px;
}
.slider-nav .slide-next {
  right: 23px;
}

@media (max-width: 991px) {
  .slider-nav .slide-prev,
  .slider-nav .slide-next {
    font-size: 2rem;
    transform: unset;
    top: unset;
    bottom: 0.5rem;
  }
  .theme-slider.slider-lg {
    min-height: 400px;
    height: 400px;
  }
}
@media (max-width: 767px) {
  .theme-slider.slider-lg {
    min-height: unset;
    height: 350px;
  }
}
@media (max-width: 575px) {
  .theme-slider.slider-lg {
    min-height: unset;
    height: 300;
  }
}
.image-pins {
  position: relative;
}
.image-pins .pin {
  position: absolute;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
  background: rgb(25, 44, 81);
  background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
  border-radius: 50%;
  opacity: 1;
  transition: 0.2s;
}
.image-pins .pin::before {
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgb(25, 44, 81);
  background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
  opacity: 0.4;
  transition: 0.2s;
  pointer-events: none;
}
.image-pins .pin:hover::before {
  animation: circleBeat;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}

@keyframes circleBeat {
  0% {
    height: 20px;
    width: 20px;
  }
  50% {
    height: 30px;
    width: 30px;
  }
  100% {
    height: 20px;
    width: 20px;
  }
}
.icon-wrapper {
  display: inline-block;
}
.icon-wrapper:not(.icon-wrapper-size) .icon {
  max-width: 100%;
  width: 55px;
}
.icon-wrapper.icon-rounded {
  border-radius: 100%;
}
.icon-wrapper.icon-md {
  width: 65px;
  padding: 18px;
}
.icon-wrapper.icon-md img {
  width: 100%;
}

.icon.icon-rounded {
  border-radius: 50%;
}
.icon.icon-md {
  font-size: 1.5rem;
  height: 40px;
  width: 40px;
}
.icon.icon-secondary {
  background-color: #fff;
  color: #192c51;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb.breadcrumb-style-1 .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
}
.breadcrumb.breadcrumb-style-1 .breadcrumb-item a {
  font-weight: 500;
  text-decoration: none;
}
.breadcrumb.breadcrumb-style-1 .breadcrumb-item.active {
  color: #b52533;
  font-weight: 500;
}

.side-nav.side-nav-fixed {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
  background-color: #fff;
  z-index: 99;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  transition: transform 0.3s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.side-nav.side-nav-fixed.state-open {
  transform: translateY(-50%);
}
.side-nav.side-nav-fixed .side-nav-toggle {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%) translateX(100%);
  background-color: #b52533;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  text-decoration: none;
  color: #fff;
  box-shadow: 5px 0 12px -2px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
}
.side-nav.side-nav-fixed nav {
  padding: 10px;
}
.side-nav.side-nav-fixed nav .nav-link {
  color: #272626;
  font-weight: 400;
  font-size: 0.8rem;
}
.side-nav.side-nav-fixed nav .nav-link:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.overlay-1::before,
.overlay-2::before,
.overlay-3::before,
.overlay-white-1::before,
.overlay-gradient-1::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
}

.overlay-1::before {
  background-color: transparent;
  background-image: linear-gradient(290deg, rgba(0, 0, 0, 0.0392156863) 0%, #000 100%);
}

.overlay-2::before {
  background: rgb(0, 0, 0);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.overlay-white-1::before {
  background: rgba(255, 255, 255, 0.8);
}

.overlay-white-2::before {
  background: rgba(255, 255, 255, 0.7);
}

.overlay-gradient-1::before {
  background: rgb(25, 44, 81);
  background: linear-gradient(270deg, rgb(44, 66, 111) 0%, rgb(57, 81, 129) 51%, rgb(34, 57, 102) 100%);
  opacity: 0.85;
}

.underline-sm::after {
  content: "";
  width: 50px;
  border-top: 7px solid #192c51;
  display: block;
  margin: 10px auto 0 auto;
  border-radius: 10px;
}

.side-img-wrapper {
  position: relative;
}
.side-img-wrapper .img-side {
  position: absolute;
  outline: 10px solid #fff;
  max-width: 300px;
  min-width: 30%;
  bottom: 0;
}
.side-img-wrapper .img-side.img-side-right {
  right: 0;
}
.side-img-wrapper .img-side.img-side-left {
  left: 0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.bg-img.bg-repeat {
  background-repeat: repeat;
}

.dropdown-menu.dropdown-menu-style-1 {
  border-radius: 0;
  border: none;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s opacity;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}
.dropdown-menu.dropdown-menu-style-1.show {
  opacity: 1;
  visibility: visible;
}
.dropdown-menu.dropdown-menu-style-1 li {
  padding: 0.6rem 0.7rem;
  font-size: 0.78rem;
}
.dropdown-menu.dropdown-menu-style-1 li:not(:last-child) {
  margin-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.section-navigation {
  position: sticky;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.section-navigation .dot {
  width: 12px;
  height: 12px;
  background-color: #e8e8e8;
  border-radius: 50%;
  padding: 2px;
  outline: 2px solid #e8e8e8;
  outline-offset: 2px;
  line-height: 0;
  display: inline-block;
}
.section-navigation .dot:not(:last-child) {
  margin-bottom: 15px;
}

.supertitle {
  font-weight: 700;
  color: #b52533;
}

.filter-invert {
  filter: invert(1);
}

.service-item {
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: #fff;
}

.z-1 {
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}

@media (min-width: 1200px) {
  .dropdown-menu.dropdown-menu-style-1 li {
    font-size: 0.9rem;
  }
}
@media (max-width: 767px) {
  .side-img-wrapper .img-side {
    display: none;
  }
}
header {
  box-shadow: none;
  transition: 0.3s background-color;
}
header .navbar {
  transition: 0.3s;
}
header .navbar .logo {
  transition: 0.3s;
}
header .navbar .nav-link {
  transition: 0.3s;
}
header.state-scroll {
  position: fixed;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
  transform: translateY(-110px);
}
header.state-scroll .navbar {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  background-color: #fff;
}
header.state-scroll .navbar .logo {
  width: 90px;
}
header.state-scroll .navbar .nav-link,
header.state-scroll .navbar .dropdown-toggle {
  color: #272626;
}
header.state-scroll .navbar::before {
  visibility: visible;
}
header.state-scroll .nav-item-logo {
  display: block;
}

.slick-slider .slide-effect-x.slide .slide-img {
  width: 105%;
  left: 0;
  transition: 0;
}
.slick-slider .slide-effect-x.slide.animate.slick-current .slide-img {
  transition: 10s linear;
  left: -5%;
}

@media (max-width: 1200px) {
  .mobile-menu {
    padding: 0;
    position: relative;
    width: 35px;
    height: 35px;
  }
  .mobile-menu:before, .mobile-menu::after {
    content: "";
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  .mobile-menu span, .mobile-menu::before, .mobile-menu::after {
    display: inline-block;
    width: 35px;
    height: 3px;
    background-color: #272626;
    position: absolute;
    left: -1px;
    z-index: 1;
    transition: 0.3s;
  }
  .mobile-menu::before {
    top: 5px;
    animation-name: firstLineOut;
  }
  .mobile-menu span {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
  .mobile-menu::after {
    bottom: 5px;
    animation-name: secondLineOut;
  }
  .mobile-menu.open::before {
    animation-name: firstLineIn;
  }
  .mobile-menu.open::after {
    animation-name: secondLineIn;
  }
  .mobile-menu.open::before, .mobile-menu.open::after {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  .mobile-menu.open span {
    opacity: 0;
  }
  .mobile-menu:focus {
    border: none;
    box-shadow: none;
  }
  @keyframes firstLineIn {
    0% {
      top: 5px;
      transform: translateY(0) rotate(0);
    }
    50% {
      top: 50%;
      transform: translateY(-50%);
    }
    100% {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  @keyframes firstLineOut {
    0% {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }
    50% {
      top: 50%;
      transform: translateY(-50%);
    }
    100% {
      top: 5px;
      transform: translateY(0) rotate(0);
    }
  }
  @keyframes secondLineIn {
    0% {
      bottom: 5px;
      transform: translateY(0) rotate(0);
    }
    50% {
      bottom: 50%;
      transform: translateY(50%);
    }
    100% {
      bottom: 50%;
      transform: translateY(50%) rotate(-45deg);
    }
  }
  @keyframes secondLineOut {
    0% {
      bottom: 50%;
      transform: translateY(50%) rotate(-45deg);
    }
    50% {
      bottom: 50%;
      transform: translateY(50%);
    }
    100% {
      bottom: 5px;
      transform: translateY(0) rotate(0);
    }
  }
}
@media (max-width: 991px) {
  .mobile-menu span, .mobile-menu::before, .mobile-menu::after {
    background-color: #fff;
  }
}
@media (min-width: 992px) {
  header .navbar {
    transition: 0.3s;
  }
}
